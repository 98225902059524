import { ArticleTagsListContainer } from '@/components/ArticleTagsListContainer.tsx'
import { ArticleTag } from '@/components/ds/ArticleTag.tsx'
import { Bookmark } from '@/components/icons/Bookmark.tsx'
import { Badge } from '@/components/ui/badge.tsx'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card.tsx'
import { cn } from '@/lib/utils.ts'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { nanoid } from '@reduxjs/toolkit'
import { useAppDispatch } from '@/store/hooks.ts'
import { openAuthModal } from '@/store/modal/modalsSlice.ts'

type LegalWatchArticleActions = {
  onReadArticle: (slug: LegalWatchArticle['slug'], id: LegalWatchArticle['id']) => void
  onToggleFavorite: (id: LegalWatchArticle['id'], isFavorite: LegalWatchArticle['isFavorite']) => void
  isUserConnected: boolean
}

export type LegalWatchArticlePreviewProps = LegalWatchArticle & LegalWatchArticleActions & { className?: string }

export function LegalWatchArticlePreview({
  title,
  createdAt,
  excerpt,
  id,
  onReadArticle,
  onToggleFavorite,
  isFavorite,
  region,
  tags,
  slug,

  isRead, // Add this line to remove publishedAt from props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  publishedAt, // Add this line to remove publishedAt from props
  className,
  isUserConnected,
  ...props
}: Readonly<LegalWatchArticlePreviewProps>) {
  const dispatch = useAppDispatch()
  return (
    <Card
      className={cn(
        'shadow-softBlueGreyUnderglow group h-[280px] w-full rounded-2xl border-none bg-bright ease-in-out hover:cursor-pointer hover:bg-softMint',
        className,
      )}
      onClick={() => onReadArticle(slug, id)}
      {...props}
    >
      <CardHeader className={'flex flex-row items-center justify-between pb-4'}>
        <div className={'flex items-center gap-3.5'}>
          <Badge
            variant={'custom'}
            className={
              'border-none px-0 text-label transition-all duration-300 ease-in-out group-hover:bg-mintGreen group-hover:px-4 group-hover:py-1 group-hover:text-bright'
            }
          >
            {region}
          </Badge>
          <CardDescription className={'text-xs text-label transition-all duration-300 ease-in-out'}>
            {createdAt}
          </CardDescription>
        </div>
        <Bookmark
          onClick={(event) => {
            event.stopPropagation()
            if (isUserConnected) {
              onToggleFavorite(id, isFavorite)
            } else {
              dispatch(openAuthModal())
            }
          }}
          className={`${isFavorite ? 'fill-mintGreen stroke-mintGreen' : 'fill-bright stroke-mintGreen group-hover:fill-softMint'}`}
        />
      </CardHeader>
      <CardContent className={'flex flex-col gap-3.5'}>
        <CardTitle
          className={`${isRead ? 'font-normal text-label' : 'font-semibold text-primary'} line-clamp-2 min-h-10 text-base leading-5`}
        >
          {title}
        </CardTitle>
        <p className={`${isRead ? 'text-silver' : 'text-label'} line-clamp-2 min-h-10 text-sm`}>{excerpt}</p>
      </CardContent>
      <CardFooter className={'flex items-center gap-2'}>
        <ArticleTagsListContainer>
          {tags?.map((tag) => (
            <ArticleTag
              className={`${isRead && 'border border-solid border-silver text-silver'}`}
              key={nanoid()}
              tag={tag}
            />
          ))}
        </ArticleTagsListContainer>
      </CardFooter>
    </Card>
  )
}
