import { useLocation, useNavigate } from 'react-router-dom'
import { RoutePathsItem } from '@/ROUTE_PATHS.ts'

export const useBackButtonContainerHook = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const navigateBack = (path: RoutePathsItem) => {
    return location.pathname.includes('conversations') ? navigate(path, { replace: true }) : navigate(-1)
  }
  return {
    navigateBack,
  }
}
