import { LegalWatchArticleList } from '@/components/legalWatch/LegalWatchArticleList.tsx'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { LegalWatchArticlesListSkeleton } from '@/components/LegalWatchArticlesListSkeleton.tsx'

interface Props {
  articles: LegalWatchArticle[]
  isFetchingArticles?: boolean
  skeletons?: number[]
}

export function LegalWatchArticleListContainer({ articles, isFetchingArticles, skeletons }: Readonly<Props>) {
  console.log(isFetchingArticles)
  if (isFetchingArticles) return <LegalWatchArticlesListSkeleton skeletons={skeletons ?? []} />

  if (articles.length === 0)
    return <span className={'text-lg font-normal text-label'}>Aucun article ne correspond à la recherche.</span>

  return (
    <div className={'flex flex-col gap-6'}>
      <LegalWatchArticleList className="gap-5 lg:grid-cols-2 xl:grid-cols-3" articles={articles} />
    </div>
  )
}
