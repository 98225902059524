import { Skeleton } from './ui/skeleton'
import { cn } from '@/lib/utils.ts'

export function LegaWatchArticlePreviewSkeleton() {
  return (
    <Skeleton
      className={cn(
        'shadow-softBlueGreyUnderglow group flex h-[17.5rem] flex-col gap-4 rounded-2xl border-none bg-bright p-4 ease-in-out',
      )}
    >
      <div className={'flex flex-col gap-5'}>
        <div className={'flex gap-4'}>
          <Skeleton className={'h-4 w-[75px] bg-bInput'} />
          <Skeleton className={'h-4 w-[75px] bg-bInput'} />
        </div>
        <Skeleton className="h-8 w-full bg-bInput" />
        <Skeleton className="h-6 w-full bg-bInput" />
      </div>
      <div className={'flex flex-col gap-5'}>
        <Skeleton className="h-4 w-[150px] bg-bInput" />
        <Skeleton className="h-4 w-[150px] bg-bInput" />
        <Skeleton className="h-4 w-[150px] bg-bInput" />
      </div>
    </Skeleton>
  )
}
