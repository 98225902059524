import React from 'react'
import { cn } from '@/lib/utils.ts'
import { LegaWatchArticlePreviewSkeleton } from '@/components/LegaWatchArticlePreviewSkeleton.tsx'

interface LegalWatchArticlesListSkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  skeletons: number[]
}

export function LegalWatchArticlesListSkeleton({
  className,
  skeletons,
}: Readonly<LegalWatchArticlesListSkeletonProps>) {
  return (
    <div className={cn(`grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-3`, className)}>
      {skeletons.map((skeleton) => (
        <LegaWatchArticlePreviewSkeleton key={skeleton} />
      ))}
    </div>
  )
}
