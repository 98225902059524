import { FullHeightLayout } from '@/pages/prompt-tutorial/FullHeightLayout.tsx'
import { LegalWatchContentLayout } from '@/components/legalWatch/LegalWatchContentLayout.tsx'
import { BackButtonContainer } from '@/components/BackButtonContainer.tsx'
import { LegalWatchArticleListContainer } from '@/components/LegalWatchArticleListContainer.tsx'
import { useLegalWatchArticleListHook } from '@/components/legalWatch/use-legalWatchArticleList.hook.tsx'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'
import PaginationContainer from '@/components/PaginationContainer.tsx'
import { usePaginationHook } from '@/usePaginationHook.tsx'
import { useEffect } from 'react'
import { listArticles } from '@/store/articles/useCases/listArticles.ts'
import { useAppDispatch } from '@/store/hooks.ts'

export function LegalWatchFavorites() {
  const { favoritesArticles } = useLegalWatchArticleListHook()
  const { paginatedArticles } = usePaginationHook(favoritesArticles)
  const dispatch = useAppDispatch()
  const { isFetchingArticles } = useLegalWatchArticleListHook()

  useEffect(() => {
    const fetch = () => {
      dispatch(listArticles())
    }
    fetch()
  }, [])

  return (
    <FullHeightLayout className={'sm:py-8'}>
      <LegalWatchContentLayout>
        <BackButtonContainer path={ROUTE_PATHS.legalWatch} label={'Veille juridique'} />
        <h1 className={'text-[1.75rem] font-semibold leading-9'}>Mes favoris</h1>
        <>
          <LegalWatchArticleListContainer
            articles={paginatedArticles}
            isFetchingArticles={isFetchingArticles}
            skeletons={Array.from({ length: 15 }, (_, i) => i + 1)}
          />
          <PaginationContainer isFetchingArticles={isFetchingArticles} articles={favoritesArticles} />
        </>
      </LegalWatchContentLayout>
    </FullHeightLayout>
  )
}
