import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationFirst,
  PaginationItem,
  PaginationLast,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination.tsx'
import { cn } from '@/lib/utils.ts'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { usePaginationHook } from '@/usePaginationHook.tsx'

interface Props {
  articles: LegalWatchArticle[]
  isFetchingArticles: boolean
}

function PaginationContainer({ articles, isFetchingArticles }: Readonly<Props>) {
  const { handlePageClick, handlePrevious, handleNext, pagesToShow, totalPages, currentPage } =
    usePaginationHook(articles)

  if (totalPages === 0 || isFetchingArticles) return null
  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem className={cn('hover:cursor-pointer', currentPage === 1 && 'hover:cursor-not-allowed')}>
          <PaginationFirst disabled={currentPage === 1} onClick={() => handlePageClick(1)} />
        </PaginationItem>

        <PaginationItem className={cn('hover:cursor-pointer', currentPage === 1 && 'hover:cursor-not-allowed')}>
          <PaginationPrevious disabled={currentPage === 1} onClick={handlePrevious} />
        </PaginationItem>

        {/* Ellipsis before if the first page in pagesToShow is > 1 */}
        {pagesToShow.length > 0 && pagesToShow[0] > 1 && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}

        {/* Render only prev/current/next pages */}
        {pagesToShow.map((pageNumber) => (
          <PaginationItem className={'hover:cursor-pointer'} key={pageNumber}>
            <PaginationLink onClick={() => handlePageClick(pageNumber)} isActive={pageNumber === currentPage}>
              {pageNumber}
            </PaginationLink>
          </PaginationItem>
        ))}

        {/* Ellipsis after if the last page in pagesToShow is < totalPages */}
        {pagesToShow.length > 0 && pagesToShow[pagesToShow.length - 1] < totalPages && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}

        <PaginationItem
          className={cn('hover:cursor-pointer', currentPage === totalPages && 'hover:cursor-not-allowed')}
        >
          <PaginationNext disabled={currentPage === totalPages} onClick={handleNext} />
        </PaginationItem>

        <PaginationItem
          className={cn('hover:cursor-pointer', currentPage === totalPages && 'hover:cursor-not-allowed')}
        >
          <PaginationLast disabled={currentPage === totalPages} onClick={() => handlePageClick(totalPages)} />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  )
}
export default PaginationContainer
