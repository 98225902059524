import * as React from 'react'
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, MoreHorizontal } from 'lucide-react'

import { cn } from '@/lib/utils'
import { ButtonProps, buttonVariants } from '@/components/ui/button'

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
)
Pagination.displayName = 'Pagination'

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(
  ({ className, ...props }, ref) => (
    <ul ref={ref} className={cn('flex flex-row items-center gap-1', className)} {...props} />
  ),
)
PaginationContent.displayName = 'PaginationContent'

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn('', className)} {...props} />
))
PaginationItem.displayName = 'PaginationItem'

type PaginationLinkProps = {
  isActive?: boolean
} & Pick<ButtonProps, 'size' | 'disabled'> &
  React.ComponentProps<'a'>

const PaginationLink = ({ className, isActive, disabled, size = 'icon', ...props }: PaginationLinkProps) => (
  <a
    aria-current={isActive ? 'page' : undefined}
    aria-disabled={disabled}
    tabIndex={disabled ? -1 : undefined}
    className={cn(
      buttonVariants({
        variant: isActive ? 'outline' : 'ghost',
        size,
      }),
      isActive ? 'border-mintGreen text-mintGreen' : 'text-gray-500 hover:text-mintGreen',
      disabled && 'pointer-events-none cursor-not-allowed opacity-50',
      className,
    )}
    {...props}
  />
)
PaginationLink.displayName = 'PaginationLink'

const PaginationFirst = ({ className, disabled, ...props }: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Aller à la première page"
    size="default"
    disabled={disabled}
    className={cn(
      'gap-1 pl-2.5 text-mintGreen hover:bg-softMint',
      disabled && 'cursor-not-allowed text-gray-400 hover:bg-transparent',
      className,
    )}
    {...props}
  >
    <ChevronsLeft className="h-4 w-4" />
    <span>Premier</span>
  </PaginationLink>
)
PaginationFirst.displayName = 'PaginationFirst'

const PaginationLast = ({ className, disabled, ...props }: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Aller à la dernière page"
    size="default"
    disabled={disabled}
    className={cn(
      'gap-1 pl-2.5 text-mintGreen hover:bg-softMint',
      disabled && 'cursor-not-allowed text-gray-400 hover:bg-transparent',
      className,
    )}
    {...props}
  >
    <span>Dernier</span>
    <ChevronsRight className="h-4 w-4" />
  </PaginationLink>
)
PaginationLast.displayName = 'PaginationLast'

const PaginationPrevious = ({ className, disabled, ...props }: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Aller à la page précédente"
    size="default"
    disabled={disabled}
    className={cn(
      'gap-1 pl-2.5 text-mintGreen hover:bg-softMint',
      disabled && 'cursor-not-allowed text-gray-400 hover:bg-transparent',
      className,
    )}
    {...props}
  >
    <ChevronLeft className="h-4 w-4" />
    <span>Précédent</span>
  </PaginationLink>
)
PaginationPrevious.displayName = 'PaginationPrevious'

const PaginationNext = ({ className, disabled, ...props }: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label="Aller à la page suivante"
    size="default"
    disabled={disabled}
    className={cn(
      'gap-1 pr-2.5 text-mintGreen hover:bg-softMint',
      disabled && 'cursor-not-allowed text-gray-400 hover:bg-transparent',
      className,
    )}
    {...props}
  >
    <span>Suivant</span>
    <ChevronRight className="h-4 w-4" />
  </PaginationLink>
)
PaginationNext.displayName = 'PaginationNext'

const PaginationEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => (
  <span aria-hidden className={cn('flex h-9 w-9 items-center justify-center text-mintGreen', className)} {...props}>
    <MoreHorizontal className="h-4 w-4" />
  </span>
)
PaginationEllipsis.displayName = 'PaginationEllipsis'

export {
  Pagination,
  PaginationContent,
  PaginationLink,
  PaginationItem,
  PaginationPrevious,
  PaginationNext,
  PaginationEllipsis,
  PaginationFirst,
  PaginationLast,
}
