import {
  selectArticleFavorites,
  selectFilteredArticles,
  selectHomeArticles,
  selectIsFetchingArticles,
  selectUnReadArticles,
} from '@/store/articles/articlesSlice'
import { useAppSelector } from '@/store/hooks.ts'

export function useLegalWatchArticleListHook(limit = 3) {
  const filteredArticles = useAppSelector(selectFilteredArticles)
  const unReadArticles = useAppSelector(selectUnReadArticles)
  const favoritesArticles = useAppSelector(selectArticleFavorites)
  const isFetchingArticles = useAppSelector(selectIsFetchingArticles)
  const homeArticles = useAppSelector(selectHomeArticles(limit))

  return {
    articles: filteredArticles,
    unReadArticles,
    favoritesArticles,
    isFetchingArticles,
    homeArticles,
  }
}
