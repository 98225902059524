import { FullHeightLayout } from '@/pages/prompt-tutorial/FullHeightLayout.tsx'
import { LegalWatchArticleListContainer } from '@/components/LegalWatchArticleListContainer.tsx'
import { useLegalWatchArticleListHook } from '@/components/legalWatch/use-legalWatchArticleList.hook.tsx'
import { LegalWatchHeaderContainer } from '@/components/LegalWatchHeaderContainer.tsx'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs.tsx'
import { LegalWatchFiltersContainer } from '@/components/LegalWatchFiltersContainer'
import { useEffect } from 'react'
import { LOCAL_STORAGE_REDIRECTION_KEY } from '@/ROUTE_PATHS.ts'
import PaginationContainer from '@/components/PaginationContainer.tsx'
import { usePaginationHook } from '@/usePaginationHook.tsx'
import { listArticles } from '@/store/articles/useCases/listArticles.ts'
import { useAppDispatch } from '@/store/hooks.ts'

export function LegalWatch() {
  const { articles, unReadArticles, isFetchingArticles } = useLegalWatchArticleListHook()
  const { paginatedArticles: paginatedAllArticles } = usePaginationHook(articles)
  const { paginatedArticles: paginatedUnreadArticles } = usePaginationHook(unReadArticles)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const redirectionValue = localStorage.getItem(LOCAL_STORAGE_REDIRECTION_KEY)
    if (redirectionValue) localStorage.removeItem(LOCAL_STORAGE_REDIRECTION_KEY)
  }, [])

  useEffect(() => {
    const fetch = () => {
      dispatch(listArticles())
    }
    fetch()
  }, [])

  return (
    <FullHeightLayout>
      <div
        className={`flex w-full flex-col gap-6 px-4 pb-4 pt-8 transition-all duration-500 ease-in-out 2xl:mx-auto 2xl:max-w-1400`}
      >
        <LegalWatchHeaderContainer>
          <Tabs defaultValue={'all'} className={'gap-4'}>
            <TabsList className={'mb-5 bg-inherit'}>
              <TabsTrigger
                className={
                  'data-[state=active]:border-x-none text-xl font-normal leading-5 text-label transition-none data-[state=active]:rounded-none data-[state=active]:border-2 data-[state=active]:border-x-transparent data-[state=active]:border-b-mintGreen data-[state=active]:border-t-transparent data-[state=active]:bg-snowMist data-[state=active]:text-mintGreen data-[state=active]:shadow-none'
                }
                value="all"
              >
                Tous
              </TabsTrigger>
              <div>
                <TabsTrigger
                  className={
                    'data-[state=active]:border-x-none group gap-2 text-xl font-normal leading-5 text-label transition-none data-[state=active]:rounded-none data-[state=active]:border-2 data-[state=active]:border-x-transparent data-[state=active]:border-b-mintGreen data-[state=active]:border-t-transparent data-[state=active]:bg-snowMist data-[state=active]:text-mintGreen data-[state=active]:shadow-none'
                  }
                  value="unread"
                >
                  Non lus{' '}
                  <span className={'text-xl text-mistyDawn group-data-[state=active]:text-tealBlue'}>
                    {unReadArticles.length}
                  </span>
                </TabsTrigger>
              </div>
            </TabsList>
            <TabsContent value="all" className={'flex flex-col gap-5'}>
              <LegalWatchFiltersContainer />
              <LegalWatchArticleListContainer
                isFetchingArticles={isFetchingArticles}
                articles={paginatedAllArticles}
                skeletons={Array.from({ length: 15 }, (_, i) => i + 1)}
              />
              <PaginationContainer isFetchingArticles={isFetchingArticles} articles={articles} />
            </TabsContent>
            <TabsContent value="unread" className={'flex flex-col gap-5'}>
              <LegalWatchArticleListContainer articles={paginatedUnreadArticles} />
              <PaginationContainer isFetchingArticles={isFetchingArticles} articles={unReadArticles} />
            </TabsContent>
          </Tabs>
        </LegalWatchHeaderContainer>
      </div>
    </FullHeightLayout>
  )
}
