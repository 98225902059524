import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'

export const usePaginationHook = (articles: LegalWatchArticle[]) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = Number(searchParams.get('page')) || 1
  const articlesPerPage = 15

  // Calculate total pages
  const totalPages = Math.ceil(articles.length / articlesPerPage)
  // Decide which pages we actually render in the pagination
  const pagesToShow = useMemo(() => {
    // E.g. [currentPage - 1, currentPage, currentPage + 1]
    const rawPages = [currentPage - 1, currentPage, currentPage + 1]
    // Filter out pages < 1 or > totalPages
    return rawPages.filter((page) => page >= 1 && page <= totalPages)
  }, [currentPage, totalPages])

  // Get the slice of articles for the current page
  const paginatedArticles = useMemo(() => {
    const startIndex = (currentPage - 1) * articlesPerPage
    const endIndex = startIndex + articlesPerPage
    return articles.slice(startIndex, endIndex)
  }, [articles, currentPage, articlesPerPage])

  // Handlers for going to a specific page, or next/prev
  const handlePageClick = (page: number) => {
    if (page < 1 || page > totalPages) return
    searchParams.set('page', page.toString())
    setSearchParams(searchParams)
  }
  const handlePrevious = () => handlePageClick(currentPage - 1)
  const handleNext = () => handlePageClick(currentPage + 1)

  return {
    handlePrevious,
    handleNext,
    handlePageClick,
    pagesToShow,
    totalPages,
    currentPage,
    paginatedArticles,
  }
}
